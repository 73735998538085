const OmsCommon = {
  'components.osm.moreCabinet': '更多机柜',
  'components.osm.protectionLevel': '防护等级',
  'components.osm.saltSprayTest': '盐雾测试',
  'components.osm.盐雾测试': '盐雾测试',
  'components.osm.防护等级': '防护等级',
  'pages.series-index.series': '产品系列',
  'pages.series-index.cabinetSeries': '机柜系列',
  // 选型页
  'pages.series-model-select.params': '规格参数筛选',
  'pages.series-model-select.clear': '清空',
  'pages.series-model-select.noAttributeTip': '无可选规格',
  // 详情页
  'pages.series-cabinet-detail.prodDescription': '产品介绍',
  'pages.series-cabinet-detail.download': '下载',
  'pages.series-cabinet-detail.certified': '认证',
  'pages.series-cabinet-detail.addToCart': '添加至愿望清单',
  'pages.series-cabinet-detail.moreConfig': '更多配置',
  'pages.series-cabinet-detail.viewList': '查看清单',
  'pages.series-cabinet-detail.discountShort': '折',
  'pages.series-cabinet-detail.discount': '折扣',
  'pages.series-cabinet-detail.above': '{field}及以上',
  'pages.material.components.material-selector-modal.noAttributeTip': '无可选规格',
  'price.hint': '该促销价仅为标准机柜促销价格，实际促销价请参考机柜选型完成后的总价',

  'components.oms.cabinet.series': '机柜系列',
  'components.oms.immediately.match': '立即选配',
  'components.oms.cabinet.configuration': '机柜配置',
  'components.oms.hp.cabinet': 'HP机柜',
}

export default OmsCommon
